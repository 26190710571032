.outside {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 55%;
}

.spinner{
  max-width: 200px;
}

/* Circle animation. */
@keyframes rim-animation {
  100%{
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 0;
  }

  50%{
    stroke-dasharray: 141.3;
    stroke-dashoffset: 141.3;
  }

  0%{
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 282.6;
  }
}
.rim {
  display: block;
  fill: transparent;
  /* stroke: #91d1d5; */
  stroke: transparent;
  stroke-linecap: round;
  stroke-width: 5px;
  transform-origin: 50% 50%;
  max-width: 100px;
}

.rim.inner {
  animation:  rim-animation 1s cubic-bezier(1,1,1,1) 0s infinite;
  stroke-dasharray: 282.6;
  stroke-width: 3px;
  /* stroke: #218cf2; */
  stroke: #91d1d5;
}


