:root {
  --gray-light: #e9ecef;
  --gray-700: #218cf2;
  --bmbix-blue: #218cf2;
}

.App {
/*  text-align: center; */
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 24px;
}

.App-header {
  background-color: #222;
  height: 88px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.breadcrumb {
  padding-left: 0;
  background-color: var(--gray-light);
  margin-bottom: 0;
}

.blotter {
  background-color: var(--white);
}

.topbar {
  /* background-color: var(--bmbix-blue); */
}

.trailbar{
  background-color: var(--gray-light);
}

.page{
  background-color: var(--white);
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: var(--gray-light);
}


body, .btn {
  font-size: .75rem;
}

.ReactTable {
  margin-top: 1rem;
}

.nav-tabs {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.topbar .navbar {
  padding-left: 0;
}

.navbar-brand {
  padding-bottom: 0.8rem;
}

.navbar {
  padding-left: 0;
  padding-right: 0;
}

html.h-100 body.h-100 div#root.d-flex.flex-column.h-100 div.bg-primary.container-fluid div.row div.col div.bg-primary.container-fluid div.row div.col nav.navbar.navbar-expand.navbar-dark.bg-primary div.navbar-nav a.house.nav-link {
  padding-left: 0;
}

#dropdown-menu-user {
  padding-right: 0;
}

.house {
  padding-left: 0;
}
